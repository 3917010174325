<template>
  <div data-aos="fade-up" data-aos-duration="600">
    <div class="wpo-breadcumb-area">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="wpo-breadcumb-wrap">
              <h2>Resources</h2>
              <ul>
                <li><router-link to="/">Home</router-link></li>
                <li><span>Contact</span></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

    <section class="wpo-contact-form-map section-padding">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="row">
              <div class="col col-lg-6 col-md-6 col-sm-12 col-12">
                <div class="resources-box-after">
                  <div class="resources-box">
                    <div class="gooey" v-if="loader" style="height: 500px">
                      <img src="../assets/images/Infinity-loader.gif" alt="">
                    </div>
                    <ul v-else>
                      <li v-for="(resources, index) in resources_data" :key="index">
                        <span v-if="resources[0]">{{ resources[0].category }}</span>
                        <ol>
                          <li v-for="item in resources" :key="item.id" v-if="item.status === 'published'">
                            <a :href="item.link_url" target="_blank" style="display:flex; align-items:end; justify-content: space-between;">
                              {{item.name}}
                              <div  >
                              <img src="/right-arrow.svg" class="right_arrow_image" v-if="item.link_url"/>
                            </div>
                            </a> 
                          </li>
                        </ol>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="col col-lg-6 col-md-6 col-sm-12 col-12">
                <div class="contact-form">
                  <h2>Get In Touch</h2>
                  <form method="post" class="contact-validation-active" id="contact-form" action="https://flexibleforms.co/f/i2m9zedr1tlKvrZ0rey1" @submit="handleSubmit">
                    <div>
                      <input type="text" class="form-control" name="name" id="name" placeholder="First Name" required>
                    </div>
                    <div>
                      <input type="text" class="form-control" name="name2" id="name2" placeholder="Last Name" required>
                    </div>
                    <div class="clearfix">
                      <input type="email" class="form-control" name="email" id="email" placeholder="Email" required>
                    </div>
                    <div>
                      <input type="text" class="form-control" name="subject" id="subject" placeholder="Subject" required>
                    </div>
                    <div>
                      <textarea class="form-control" name="note"  id="note" placeholder="Case Description..." required maxlength="1000"></textarea>
                    </div>
                    <div class="submit-area">
                      <button type="submit" class="theme-btn submit-btn" id="submitbtn" :disabled="submit_btnDisabled">Send Message</button>
                      <div id="loader">
                        <i class="ti-reload"></i>
                      </div>
                    </div>
                    <div class="clearfix error-handling-messages">
                      <div id="success">Thank you</div>
                      <div id="error"> Error occurred while sending email. Please try again later. </div>
                    </div>
                  </form>
                </div>

                <div class="wpo-contact-info" v-if="contact_data.status === 'published'">
                  <div class="col-12">
                    <div class="info-item">
                      <div class="info-wrap">
                        <div class="info-icon">
                          <i class="ti-world"></i>
                        </div>
                        <div class="info-text">
                          <span>{{contact_data.location}}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="info-item">
                      <div class="info-wrap">
                        <div class="info-icon-2">
                          <i class="fi flaticon-envelope"></i>
                        </div>
                        <div class="info-text">
                          <span>{{contact_data.email}}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="info-item">
                      <div class="info-wrap">
                        <div class="info-icon-3">
                          <i class="ti-headphone-alt"></i>
                        </div>
                        <div class="info-text">
                          <span>{{contact_data.phone}}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> <!-- end container -->
    </section>
  </div>
</template>

<script>
    import api from "../services/api";

    export default {
        name: "contact",
        data() {
            return {
                contact_data: [],
                resources_data: [],
                loader: true,
                submit_btnDisabled: false,
            }
        },
        methods: {
          handleSubmit() {
            this.submit_btnDisabled = true;
            document.getElementById('submitbtn').classList.add('theme-btn-disabled');
          }
        },
        async mounted () {
            window.scrollTo(0, 0);

            this.contact_data = await api.contact();
            this.contact_data = this.contact_data.data[0];

            const resources = await api.resources();
            this.resources_data = resources.data;
            
            for (let i in this.resources_data) {
                if (this.resources_data[i].category) {
                    let id = this.resources_data[i].category
                    const name = await api.getCategoryName(id);
                    this.resources_data[i].category = name.data.name
                }
            }
            
            /*
            this.resources_data.sort((a, b) => {
                if (a.sort < b.sort) {
                    return -1;
                }

                if (a.sort > b.sort) {
                    return 1;
                }

                return 0;
            });
            */
            
            this.resources_data = this.resources_data.reduce((groups, item) => {
                const group = (groups[item.category] || []);
                group.push(item);
                groups[item.category] = group;
                return groups;
            }, {});

            this.loader = false;
        }
    }
</script>

<style scoped>
  .gooey {
    width: 100%;
    height: 400px;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .info-item h2 {
    min-height: 52px;
  }
  .resources-box {
    padding-right: 10px;
    position: relative;
  }
  .resources-box-after {
    padding: 10px;
    box-shadow: 0 5px 15px 0 rgba(68, 68, 68, 0.1);
  }
  .resources-box::-webkit-scrollbar-track {
    background-color: #fff;
  }

  .resources-box::-webkit-scrollbar {
    width: 8px;
    background-color: #F5F5F5;
  }

  .resources-box::-webkit-scrollbar-thumb {
    background-color: #ccc;
  }
  .wpo-contact-form-map ul, ol {
    list-style: none;
    padding-left: 20px;
  }
  .wpo-contact-form-map ul>li>span {
    display: block;
    padding: 5px 10px;
    margin-bottom: 15px;
    margin-top: 25px;
    font-weight: 700;
    color: #062265;
  }
  .wpo-contact-form-map ul>li>ol>li>a {
    display: block;
    color: #444;
    padding: 10px;
    margin: 5px 0;
    border: 1px solid #f7f7f7;
    background: #f7f7f7;
  }
  .right_arrow_image {
    min-width: 14px;
    height: 14px;
    object-fit: contain;
  }
  .theme-btn-disabled {
    background: #c0a0cf;
    color: #ffff;
    border: 1px solid #c0a0cf;
    outline: none;
    margin-top: 20px;
    border-radius: 40px;
  }
</style>
